import React from "react"

function Changelog() {
  return (
    <div>
      <p className="section-title text-center">
        Changelog
      </p>
      <div className="flex flex-col mb-16">
        <ChangelogStep version="Product" date="February 2021" items={
          [
            "Pocket Integration",
            "iOS app revamp"
          ]
        }
        />
        <ChangelogStep version="Marketing" date="January 2021" items={
          [
            "Improve landing page",
            "Add use cases to landing page"
          ]
        }
        />
        <ChangelogStep version="Backend" date="December 2020" items={
          [
            "Linksaber Telegram Bot",
            "BFR: Big Fucking Refactoring (backend)"
          ]
        }
        />
        <ChangelogStep version="iOS 1.0" date="September 2020" items={
          [
            "Add ability to schedule weekly digest"
          ]
        }
        />
        <ChangelogStep version="iOS 0.2" date="August 2020" items={
          [
            "Add unsubscribe from emails",
            "Add option to avoid empty weekly digest"
          ]
        }
        />
        <ChangelogStep version="iOS 0.1" date="May 2020" items={
          ["Proof of concept"]
        }
        />
      </div>
    </div>
  )
}

export default Changelog

function ChangelogStep(props) {
  return (
    <div className="flex flex-col bg-gray-800 p-4 rounded-lg mt-4 mx-auto max-w-md w-full">
      <div className="flex flex-row items-center">
        <p className="bg-purple-800 text-purple-200 font-medium rounded-lg px-2 py-1">{props.version}</p>
        <p className="ml-2 font-medium">{props.date}</p>
      </div>
      <div className="flex flex-col mt-2 font-mono text-sm font-bold">
        {
          props.items.map(item => {
            return (<p key={item}>👉 {item}</p>)
          })
        }
      </div>
    </div>
  )
}