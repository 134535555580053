import { Link } from "gatsby"
import Image from "../../components/image"
import React from "react"

function CTA(props) {
  return (
    <div className={`flex md:flex-row flex-col mt-4 ${props.isAlignedLeft === true ? 'md:ml-0' : 'justify-center'}`}>
    <Link to="https://apps.apple.com/us/app/linksaber-read-later-to-inbox/id1508354807" target="_blank" className="self-center mr-2">
        <Image src={"badge_ios.png"} className="w-40"/>
      </Link>
      <Link to="https://t.me/linksaber_bot" target="_blank" className="flex flex-row self-center bg-white rounded-lg px-4 py-1 mx-2 my-5 text-gray-900">
        <Image src={"badge_telegram.webp"} className="w-10 -ml-2"/>
        <p className="self-center leading-4 ml-2">
          <span className="text-xs">Start the</span><br/>
          <span className="font-normal text-lg">Telegram Bot</span>
        </p>
      </Link>
    </div>
  )
}

export default CTA
