import { Link } from "gatsby"
import PropTypes from "prop-types"
import React from "react"
import Image from "./image"

const Header = () => (
  <header>
    <div>
      <h1>
        <Link rel="canonical" to="/">
          <div className="container mt-12 flex flex-row">
            <Image src={"logo-1024.webp"} className="logo self-center mr-6"/>

            <div className="flex flex-col self-center">
              <p className="title md:text-3xl">LINKSABER</p>
              <em className="text-sm font-bold text-blue-100 px-2 py-1 bg-blue-900 rounded-lg">NO MORE SAVE-AND-FORGET</em>
            </div>
          </div>
        </Link>
      </h1>
    </div>
  </header>
)

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
