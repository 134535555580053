import Layout from "../components/layout"
import SEO from "../components/seo"
import Header from "../components/header"
import { graphql, Link } from "gatsby"
import React from "react"
import Screenshots from "./components/Screenshots"
import CTA from "./components/CTA"
import Changelog from "./components/Changelog"
import ReactHtmlParser from 'react-html-parser'

export const query = graphql`
query($path: String!) {
  allSitePage(filter: { path: { eq: $path } }) {
    edges {
      node {
        context {
          slug
          title
          firstHeaderLine
          secondHeaderLine
        }
      }
    }
  }
}
`

const CompetitorTemplate = function(data) {
  const page = data.pageContext

  return (
    <Layout>
      <SEO title={`Linksaber | ${page.title}`}/>

      <Header/>

      <p className="section-title">
        {ReactHtmlParser(page.firstHeaderLine)}
      </p>
      <p className="section-caption italic mb-5">
        {ReactHtmlParser(page.secondHeaderLine)}
      </p>

      <CTA isAlignedLeft={true}/>

      <Screenshots/>

      <p className="section-body text-gray-400 text-center -mt-4 md:-mt-8">
        No spam - No AI™ - No suggestions - No ads<br/>
        Just your stuff, straight in your inbox<br /><br />
        Linksaber is a iOS app & Telegram Bot that allows you to save and organize your favorite read later content. It's like Pocket, but better. You can save any article or webpage to Linksaber by clicking on the Telegram Bot or iOS app and selecting the "Save" button. It will then be saved in the system for you to go back to at any time.<br/><br/>In this section, we will explore the use of read later links in your email inbox.

One of the most important features of a good read later email system is the ability to save it for reading at a later time. This feature is called a read-later link, and it’s also known as an “in-mail bookmark."<br/><br/>

A read-later link saves your place in an email so that you can come back to it at a later time. It often provides a preview of what you've saved, like the first few sentences or paragraphs. You might have seen these links before: they're usually an orange or blue icon that looks like an envelope with an arrow pointing up from the bottom.<br/><br/>The read later links are very important because they allow readers to save articles for reading at a later date, rather than having to spend time searching for them again. You can even access them on your iPhone or iPad with their mobile app. It also sends you a weekly email reminder with the links. Linksaber is a new read it later app that is better than other apps in the market. It has a simple interface and has all the features you would need to save articles for reading later.<br/><br/>

It also syncs your reading list across devices with ease, so you can read on your laptop or mobile phone without any hassle.<br/>

Linksaber is an app that lets you save links from anywhere and read them on your device at a time of your choosing. Linksaber syncs across all devices and lets you access it from anywhere, so there’s no need to worry about missing out on something important just because you’re not near your computer or phone. Linksaber is a read later app for email lovers.<br/>

Linksaber is a read-later app that helps you save links from your email inbox to your phone or tablet. It's built to be fast and simple, so you don't have to worry about any complicated steps or configurations.<br/>

Linksaber has been designed with simplicity in mind, so it's easy to use and takes just a few seconds to setup. All you need is an email address and then you're ready to go!
      </p>

      <p className="section-title text-center">
        Give it a shot
      </p>
      <p className="section-body text-gray-400 text-center">
        Early users are loving it:
        <br/>400+ weekly digests sent so far.
        <br/>700+ links saved by 50+ early users.
        <br/>Get on board, you're not alone.
      </p>

      <CTA isAlignedLeft={false}/>

      <Changelog/>

      <div className="text-center">
        Made with ♥️ by <Link to="https://twitter.com/albigiu/" target="_blank"
                              className="underline text-blue-300">albigiu</Link> during quarantine nights<br/>
        💌
      </div>
    </Layout>
  )
}

export default CompetitorTemplate