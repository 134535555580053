import Image from "../../components/image"
import React from "react"

function Screenshots() {
  return (
    <div>
      <div className="flex flex-row overflow-x-auto my-8 pb-10">
        <div>
          <p className="text-6xl font-extrabold text-gray-700">1</p>
          <Image src={"screen-2.webp"} className="screen -mt-8 ml-6" alt="Schedule your weekly newsletter"/>
        </div>
        <div>
          <p className="text-6xl font-extrabold text-gray-700">2</p>
          <Image src={"screen-1.webp"} className="screen -mt-8 ml-6" alt="Share links to Linksaber from any app"/>
        </div>
        <div>
          <p className="text-6xl font-extrabold text-gray-700">3</p>
          <Image src={"screen-3.webp"} className="screen -mt-8 ml-6" alt="Enjoy your saved links right from your inbox"/>
        </div>
      </div>
    </div>
  )
}

export default Screenshots